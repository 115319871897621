import React from 'react';

import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Carousel from '../components/carousel';
import Layout from '../components/layout';
import StyledName from '../components/styled-name';
import Video from '../components/video';
import WidthLimit from '../components/width-limit';

import { min } from '../constants/sizes';

const WhoIsMarkWiensPage = ({
	data: {
		whoIsMarkWiens: {
			html: contentHtml,
			frontmatter: {
				title,
			},
		},
		imageMark1: { childImageSharp: imageMark1 },
		imageMark3: { childImageSharp: imageMark3 },
		imageMarkAtTheBeach29: { childImageSharp: imageMarkAtTheBeach29 },
		imageMarkGlobalNews7: { childImageSharp: imageMarkGlobalNews7 },
	},
}) => (
	<Layout>
		<Helmet>
			<title>{title}</title>
		</Helmet>
		<main>
			<Article className="grayDotted">
				<WidthLimit>
					<h2>
						<StyledName givenName="Who Is" familyName="Mark Wiens?" />
					</h2>
					<StyledCarousel>
						<Figure>
							<Video style={{ width: '100%' }} id="30ubBQfs0cM" />
							<Figcaption>
								<p>
									Mark often supports community organizations financially and by bringing his cotton candy machine
								</p>
							</Figcaption>
						</Figure>

						<Figure>
							<Img
								style={{ width: '100%' }}
								alt=""
								fluid={{
									...imageMarkGlobalNews7.fluid,
								}}
							/>
							<Figcaption>
								<p>
									Mark Wiens is a frequent commentator on Vancouver real estate
								</p>
							</Figcaption>
						</Figure>

						<Figure>
							<Video style={{ width: '100%' }} id="vm93tKpMgmY" />
							<Figcaption>
								<p>
									One of Mark’s many property listing videos
								</p>
							</Figcaption>
						</Figure>

						<Figure>
							<Video style={{ width: '100%' }} id="Mk3EkYmRlhM" />
							<Figcaption>
								<p>
									Mark’s 2018 Christmas special
								</p>
							</Figcaption>
						</Figure>

						<Figure>
							<Img
								style={{ width: '100%' }}
								alt=""
								fluid={{
									...imageMarkAtTheBeach29.fluid,
								}}
							/>
							<Figcaption>
								<p>
									Mark filming the introduction to <i>Vancouver’s Next Top Agent</i>
								</p>
							</Figcaption>
						</Figure>

						<Figure>
							<CompositeImage>
								<Img
									alt=""
									style={{width: '37.5%'}}
									fluid={{
										...imageMark1.fluid,
									}}
								/>
								<Img
									alt=""
									style={{width: '62.5%'}}
									fluid={{
										...imageMark3.fluid,
									}}
								/>
							</CompositeImage>
							<Figcaption>
								<p>
									Mark Wiens
								</p>
							</Figcaption>
						</Figure>
					</StyledCarousel>
					<div className="content" dangerouslySetInnerHTML={{ __html: contentHtml }} />
				</WidthLimit>
			</Article>
		</main>
	</Layout>
);

export default WhoIsMarkWiensPage;

const Article = styled.article`
	padding: 2rem 0;

	${min.desktop`
		padding: 4rem 0;
	`}

	& h2 {
		font-size: 1.2rem;

		${min.tablet`
			font-size: 2rem;
		`}
	}
`;

const StyledCarousel = styled(Carousel)`
	float: right;
	margin-left: 1rem;
	margin-bottom: 1rem;
	position: relative;
	background-color: rgba(0, 0, 0, 0.5);

	width: 100%;

	${min.desktop`
		width: 60%;
	`}
`;

const Figure = styled.figure`
	margin: 0;
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
`;

const Figcaption = styled.figcaption`
	text-align: center;
	font-size: 0.8rem;
	padding: 0 3rem;
	flex: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 1.5rem;

	// For IE
	width: 100%;
	box-sizing: border-box;

	& p {
		margin: 0;
	}
`;

const CompositeImage = styled.div`
	display: flex;
	width: 100%;
`;

export const query = graphql`
	query {
		whoIsMarkWiens: markdownRemark(
			fileAbsolutePath: { glob: "**/src/pages/who-is-mark-wiens.md" }
		) {
			html
			frontmatter {
				title
			}
		}
		imageMark1: file(relativePath: { eq: "mark-1.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 219, quality: 80) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		imageMark3: file(relativePath: { eq: "mark-3.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 366, quality: 80) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		imageBaseball11: file(relativePath: { eq: "Baseball-11.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 702, quality: 80) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		imageBaseball79: file(relativePath: { eq: "Baseball-79.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 702, quality: 80) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		imageMarkAtTheBeach29: file(relativePath: { eq: "MarkAtTheBeach-29.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 702, quality: 80) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		imageMarkGlobalNews7: file(relativePath: { eq: "Mark-Global-News-7.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 702, quality: 80) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;

