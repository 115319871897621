import React, { useRef, useState, useEffect, useCallback } from 'react';

import styled from 'styled-components';
import useInterval from '@use-it/interval';

import SrOnly from './sr-only';

import Sizes, { min } from '../constants/sizes';

/**
 * FIXME: this carousel is pretty hard-coded to a particular size.
 */

const Carousel = ({
	children,
	...otherProps
}) => {
	const viewportRef = useRef();
	const [auto, setAuto] = useState(true);

	const cancelAuto = useCallback(() => {
		setAuto(false);
	});

	useEffect(() => {
		if (!viewportRef.current) {
			return;
		}

		viewportRef.current.addEventListener('touchmove', cancelAuto);
		viewportRef.current.addEventListener('click', cancelAuto);

		return function cleanup() {
			viewportRef.current.removeEventListener('touchmove', cancelAuto);
			viewportRef.current.removeEventListener('click', cancelAuto);
		}
	});

	const onResize = useCallback(() => {
		goToIndex(getIndex());
	});

	useEffect(() => {
		window.addEventListener('resize', onResize);

		return function cleanup() {
			window.removeEventListener('resize', onResize);
		};
	});

	const slideCount = React.Children.count(children);

	function getWidth() {
		if (!viewportRef.current) {
			return 702;
		}
		return viewportRef.current.firstChild.firstChild.getBoundingClientRect().width;
	}

	function getIndex() {
		if (!viewportRef.current) {
			return 0;
		}
		return Math.round(viewportRef.current.scrollLeft / getWidth());
	}

	function goToIndex(index) {
		if (!viewportRef.current) {
			return;
		}
		viewportRef.current.scrollTo(index * getWidth(), 0);
	}

	function goToIndexRelative(delta) {
		goToIndex(getIndex() + delta);
	}

	useInterval(() => {
		if (getIndex() === slideCount - 1) {
			goToIndex(0);
		} else {
			goToIndexRelative(1);
		}
	}, auto ? 5e3 : null);

	function handlePrev() {
		goToIndexRelative(-1);
		cancelAuto();
	}

	function handleNext() {
		goToIndexRelative(1);
		cancelAuto();
	}

	return (
		<Wrapper {...otherProps}>
			<Viewport ref={viewportRef}>
				<Flex slideCount={slideCount}>
					{React.Children.map(children, (child) => (
						<Slide>
							{child}
						</Slide>
					))}
				</Flex>
			</Viewport>
			<Button type="button" onClick={handlePrev} style={{ left: 0 }}>
				←
				<SrOnly>Go to previous slide</SrOnly>
			</Button>
			<Button type="button" onClick={handleNext} style={{ right: 0 }}>
				→
				<SrOnly>Go to next slide</SrOnly>
			</Button>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: relative;
`;

const Viewport = styled.div`
	overflow: auto;
	scroll-snap-points-x: repeat(100%);
	scroll-snap-destination: 0 0;
	scroll-snap-type: x mandatory;
	scroll-snap-type: mandatory;
	scroll-behavior: smooth;

	// Attempt to hide scroll bar
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
`;

const Flex = styled.div`
	display: flex;
	height: 100%;

	width: ${({ slideCount }) => `calc((100vw - 2 * ${Sizes.gutterElasticVw}vw) * ${slideCount})`};
	${min.desktop`
		width: ${({ slideCount }) => `calc((100vw - 2 * ${Sizes.gutterElasticVw}vw) * 0.6 * ${slideCount})`};
	`}
	${min.max`
		width: ${({ slideCount }) => `calc(702px * ${slideCount})`};
	`}
`;

const Slide = styled.div`
	scroll-snap-align: start end;

	width: calc(100vw - 2 * ${Sizes.gutterElasticVw}vw);
	${min.desktop`
		width: calc((100vw - 2 * ${Sizes.gutterElasticVw}vw) * 0.6);
	`}
	${min.max`
		width: 702px;
	`}
`;

const Button = styled.button`
	border: none;
	background-color: transparent;
	appearance: none;
	color: inherit;
	padding: 0;
	position: absolute;

	bottom: 0px;

	// XXX: This is relying on the image being 16:9
	top: calc((100vw - 2 * ${Sizes.gutterElasticVw}vw) / (16 / 9));
	${min.desktop`
		top: calc((100vw - 2 * ${Sizes.gutterElasticVw}vw) * 0.6 / (16 / 9));
	`}
	${min.max`
		top: calc(702px / (16 / 9));
	`}

	width: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 1.5rem;
`;

export default Carousel;
